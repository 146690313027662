<template>
  <dl
    :class="bordered ? 'datalist bordered' : 'datalist'"
    :style="columnCount"
  >
    <div
      v-for="field in fields"
      :key="field.id"
      class="datalist__field"
    >
      <dt class="datalist__field-label">
        {{ field.listDisplayLabel || field.label }}
      </dt>
      <dd class="datalist__field-value">
        {{ data[field.id] }}
      </dd>
    </div>
  </dl>
</template>

<script>
export default {
  props: {
    // array of objects with "label", "id" keys
    fields: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    columns: {
      type: Number,
      default: 2
    },
    loading: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    columnCount () {
      return 'column-count: ' + this.columns
    }
  }
}
</script>

<style lang="scss" scoped>
  .datalist {
    column-gap: 2rem;

    &__field {
      margin-bottom: .5rem;
      @include text-style('interface', 'small', 'medium');
      display: grid;
      grid-template: auto auto / 1fr;
    }

    dt,
    dd {
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    &.bordered {
      dd {
        border-bottom: 1px solid $cortex;
      }
    }

    dt {
      grid-row: 1;
      grid-column: 1;
      align-self: end;
      color: $hillcock;
    }

    dd {
      grid-row: 2;
      grid-column: 1;
      @include text-weight('medium');
      min-height: 1.5rem;
    }

    @media screen and (min-width: $breakpoint-tablet) {
      dt,
      dd {
        height: 2.5rem;
      }

      dd {
        grid-row: 1;
        grid-column: 2;
      }

      &.bordered {
        dt {
          border-bottom: 1px solid $cortex;
        }
      }
    }

  }
</style>
