<template>
  <ValidationObserver v-slot="{ invalid }">
    <div
      :class="[
        `wizard-step`,
        active ? 'active' : ''
      ]"
    >
      <h2 v-if="title">
        {{ title }}
      </h2>
      <slot />
      <div class="wizard-step__actions">
        <el-button
          type="outline"
          class="back"
          @click="$parent.$emit('stepChange', -1)"
        >
          {{ isFirstStep ? 'Cancel' : 'Back' }}
        </el-button>
        <el-button
          type="primary"
          class="forward"
          :disabled="invalid"
          @click="$parent.$emit('stepChange', +1)"
        >
          {{ nextStepLabel }}
        </el-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    isFirstStep: {
      type: Boolean,
      default: false
    },
    isLastStep: {
      type: Boolean,
      default: false
    },
    nextButtonText: {
      type: String,
      default: null
    }
  },
  computed: {
    nextStepLabel() {
      // check if a button label was provided
      if (this.nextButtonText) {
        return this.nextButtonText
      }
      return this.isLastStep ? 'Complete' : 'Next'
    }
  }
}
</script>

<style lang="scss">
  .wizard-step {
    display: none;
    margin-top: 1rem;

    &.active {
      display: block;
    }

    h2 {
      margin: 0;
      @include text-style('title', 'large', 'bold');
    }

    p {
      @include text-style('paragraph', 'medium', 'regular');
    }

    &__actions {
      margin: 1.5rem 0;
    }

    button {
      margin-right: .5rem;
    }
  }
</style>
