<template>
  <form class="bf-form">
    <div
      v-for="field in fields.filter(field => field.visible === undefined || field.visible)"
      :key="field.id"
      class="field"
      :class="`field--${field.id}`"
    >
      <label
        :for="field.id"
        class="field-label"
      >
        {{ field.label }}
        <span
          v-if="field.optional"
          class="optional-text"
        >(optional)</span>
      </label>
      <div
        v-if="field.subtext"
        class="subtext"
      >
        {{ field.subtext }}
      </div>
      <ValidationProvider
        v-slot="{ errors, validate }"
        :rules="field.validationRules"
        :name="`${field.id}Validation`"
      >
        <BfSelect
          v-if="field.type === 'select'"
          v-model="data[field.id]"
          :name="field.id"
          :error="errors[0]"
          :popper-class="field.popperClass"
        >
          <el-option
            v-for="option in field.possibleValues"
            :key="option.key || option.value"
            :label="option.label"
            :value="option.value"
          />
        </BfSelect>
        <BfDatePicker
          v-else-if="field.type === 'date'"
          v-model="data[field.id]"
          :name="field.id"
          :placeholder="field.placeholder"
          :value-format="field.valueFormat"
          :disable-before="field['disable-before']"
          :disable-after="field['disable-after']"
          :error="errors[0]"
        />
        <SearchableMultiSelect
          v-else-if="field.type === 'multiselect'"
          v-model="data[field.id]"
          :options="field.possibleValues"
          :error="errors[0]"
          :popper-class="field.popperClass"
          @input="validate"
        />
        <BfInput
          v-else
          v-model="data[field.id]"
          :name="field.id"
          :type="field.type"
          :placeholder="field.placeholder"
          :error="errors[0]"
        />
      </ValidationProvider>
    </div>
  </form>
</template>

<script>
import BfInput from '@/components/BfInput/BfInput'
import BfSelect from '@/components/BfSelect/BfSelect'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import SearchableMultiSelect from '@/components/SearchableMultiSelect/SearchableMultiSelect'

/*
 * BfForm will contain minimal styling, but allow forms to be output dynamically via
 * a defined fields array and data object. Form layout should be handled by the parent.
 *
 * A "fields" array holds "field" objects that follow this pattern:
 *  {
 *    id: 'emailAddress',
 *    type: 'email',
 *    label: 'Email Address',
 *    placeholder: 'placeholder text'
 *    validationRules: 'required|email'
 *    optional: false,
 *    visible: 'true|false|undefined'
 *  }
 * see vee-validate for accepted validation rules: https://logaretm.github.io/vee-validate/guide/rules.html#rules
 * Placeholder and validationRules are optional.
 *
 * If type is "select" or "multiselect", this object also accepts:
 *  possibleValues: {
 *    *for select* label: 'Display Label',
 *    *for multiselect* displayValue: 'Display Label',
 *    value: option value
 *  }
 */
export default {
  components: {
    BfInput,
    BfSelect,
    BfDatePicker,
    SearchableMultiSelect
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .bf-form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .field {
      width: 20rem;
    }

    label {
      display: block;
      color: $black;
      @include text-style('interface', 'medium', 'medium');

      .optional-text {
        color: $hillcock;
        font-weight: 400;
      }
    }

    .subtext {
      color: $hillcock;
      @include text-style('paragraph', 'small', 'regular');
    }

    .field-wrap {
      margin-top: .5rem;
    }
  }
</style>
