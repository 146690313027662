<template>
  <div class="wizard">
    <WizardProgress
      :steps="steps"
      :current-step="currentStep"
    />
    <!-- accepts any number of WizardSteps through a generic slot -->
    <slot :current-step="currentStep" />
  </div>
</template>

<script>
import WizardProgress from './WizardProgress'

export default {
  components: {
    WizardProgress
  },
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentStep: 1
    }
  },
  created () {
    this.$on('stepChange', this.handleStepChange)
  },
  methods: {
    handleStepChange(stepIncrement) {
      this.currentStep += stepIncrement
      if (this.currentStep === 0) {
        this.$emit('cancelWizard')
      } else if (this.currentStep > this.steps.length) {
        this.$emit('completeWizard')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard {
    padding: 1rem;
  }
</style>
