export const sexOptions = [
  {
    label: 'Female, of childbearing potential',
    value: 'female_of_childbearing_potential',
    key: 'female_of_childbearing_potential'
  },
  {
    label: 'Female, not of childbearing potential',
    value: 'female_not_of_childbearing_potential',
    key: 'female_not_of_childbearing_potential'
  },
  // "female" is only a valid response for PPMI remote participants
  {
    label: 'Female',
    value: 'female',
    key: 'female'
  },
  {
    label: 'Male',
    value: 'male',
    key: 'male'
  }
]
