<template>
  <div class="enroll-participant">
    <PageHeader title="New Participant" />
    <ContentCard class="enroll-participant__page">
      <Wizard
        v-loading="loading"
        :steps="enrollmentSteps"
        @cancelWizard="backToSite"
        @completeWizard="enrollParticipant"
      >
        <template v-slot="{ currentStep }">
          <WizardStep
            title="Personal Information"
            next-button-text="Add Study Details"
            :active="currentStep === 1"
            :is-first-step="true"
          >
            <p>
              The information collected will be used to help identify the participant in the EDC.
              This information will also be used to generate a NIH research ID to help identify the patient
              across multiple studies.
            </p>
            <BfForm
              :fields="personalInformationFields"
              :data="newParticipant"
            />
          </WizardStep>
          <WizardStep
            title="Study Details"
            next-button-text="Review &amp; Complete"
            :active="currentStep === 2"
          >
            <section>
              <h4>Cohort</h4>
              <p>
                Assign the participant to a cohort. The selected cohort will determine the schedule of activities
                and CRFs to be completed for each visit.
              </p>
              <BfForm
                :fields="studyDetailFields"
                :data="newParticipant"
              />
            </section>

            <section v-if="isSubstudiesSupported">
              <h4>Enroll in Sub-study</h4>
              <SubstudySelector
                :editable-participant-data="newParticipant"
                @toggle-substudies="toggleSubstudiesAndSetNewValues"
              />
            </section>
            <section>
              <h4>GDPR</h4>
              <p>If the participant has signed GDPR Consent, document the date signed.</p>
              <BfForm
                :fields="gdprFields"
                :data="newParticipant"
              />
            </section>
          </WizardStep>
          <WizardStep
            title="Review &amp; Complete"
            :active="currentStep === 3"
            :is-last-step="true"
          >
            <p>
              Review the information provided and click Complete to add this participant to your site.
              A participant ID will automatically be assigned.
            </p>
            <h3>Personal Information</h3>
            <DataList
              :fields="personalInformationFields"
              :data="newParticipantData"
              :columns="1"
            />
            <h3>Study Details</h3>
            <DataList
              :fields="studyReviewData"
              :data="newParticipantData"
              :columns="1"
            />
          </WizardStep>
        </template>
      </Wizard>
      <Modal
        v-if="cutoffDateModalVisible"
        class="validation-modal"
        @close="closeBirthdateValidationModals"
      >
        <template v-slot:title>
          <SvgIcon
            name="warning"
            class="warning-svg"
          />
          Ineligible Participant
        </template>
        <template v-slot:content>
          <div class="errant-birth-date-field">
            <strong>Date of Birth</strong>
            <span class="errant-birth-date-value">{{ newParticipant.birthDate }}</span>
          </div>
          <p>
            This answer makes this participant ineligible for the study and cannot be overriden.
            <strong>Do not create a profile for this participant.</strong>
          </p>
          <p>
            If the answer is inaccurate, you can close this window to change your response.
          </p>
        </template>
        <template v-slot:actions>
          <el-button
            type="primary"
            @click="$router.push('/')"
          >
            Cancel Profile Creation
          </el-button>
          <el-button
            type="outline"
            @click="newParticipant.birthDate = null"
          >
            Change Answer
          </el-button>
        </template>
      </Modal>
      <Modal
        v-if="flexibleRangeDateModalVisible"
        class="validation-modal"
        @close="closeBirthdateValidationModals"
      >
        <template v-slot:title>
          <SvgIcon
            name="warning"
            class="warning-svg"
          />
          Ineligible Participant
        </template>
        <template v-slot:content>
          <div class="errant-birth-date-field">
            <strong>Date of Birth</strong>
            <span class="errant-birth-date-value">{{ newParticipant.birthDate }}</span>
          </div>
          <p>
            This answer makes this participant ineligible for the study.
            <strong>Do not create a profile for this participant unless an eligibility waiver was granted.</strong>
          </p>
          <p>
            If the answer is inaccurate, you can close this window to change your response.
          </p>
        </template>
        <template v-slot:actions>
          <el-button
            type="primary"
            @click="$router.push('/')"
          >
            Cancel Profile Creation
          </el-button>
          <el-button
            type="outline"
            @click="overrideEligibility = true, flexibleRangeDateModalVisible = false"
          >
            Override Eligibility
          </el-button>
        </template>
      </Modal>
    </ContentCard>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import Modal from '@/components/Modal/Modal'
import ContentCard from '@/components/ContentCard/ContentCard'
import Wizard from '@/components/Wizard/Wizard'
import WizardStep from '@/components/Wizard/WizardStep'
import BfForm from '@/components/BfForm/BfForm'
import DataList from '@/components/DataList/DataList'
import SubstudySelector from '@/components/SubstudySelector/SubstudySelector'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import CREATE_PARTICIPANT_MUTATION from '@/graphql/participants/CreateParticipantMutation.graphql'
import LINK_PARTICIPANT_TO_COHORT_MUTATION from '@/graphql/participants/LinkParticipantToCohortMutation.graphql'
import CREATE_PARTICIPANT_VISIT_WINDOWS_MUTATION
  from '@/graphql/participants/CreateParticipantVisitWindowsMutation.graphql'
import GET_SITE_PARTICIPANTS_QUERY from '@/graphql/participants/GetSiteParticipantsQuery.graphql'
import substudies from '@/mixins/queries/substudies'
import updateSubstudies from '@/mixins/mutations/updateSubstudies'
import { formatDateForAPI, getAge } from '@/utils/date'
import { getCohortNameById, getCohortSubgroups } from '@/utils/cohorts'
import { countryOptions } from '@/utils/countries'
import { sexOptions } from '@/utils/sex'
import { logError } from '@/utils/logging'
import { find, propEq, propOr, sortBy, compose, toUpper } from 'ramda'

export default {
  components: {
    PageHeader,
    ContentCard,
    Wizard,
    WizardStep,
    BfForm,
    DataList,
    Modal,
    SubstudySelector
  },
  mixins: [
    substudies,
    updateSubstudies
  ],
  data() {
    return {
      loading: false,
      enrollmentSteps: [
        { title: `Personal Information` },
        { title: `Study Details` },
        { title: `Review & Complete` }
      ],
      cutoffDateModalVisible: false,
      flexibleRangeDateModalVisible: false,
      overrideEligibility: false,
      cohorts: [],
      personalInformationFields: [
        {
          id: 'firstName',
          label: 'First Name',
          type: 'text',
          validationRules: 'required'
        },
        {
          id: 'middleName',
          label: 'Middle Name',
          type: 'text',
          optional: true
        },
        {
          id: 'familyName',
          label: 'Last Name',
          type: 'text',
          validationRules: 'required'
        },
        {
          id: 'emailAddress',
          label: 'Email Address',
          type: 'email',
          validationRules: 'email'
        },
        {
          id: 'birthDate',
          label: 'Date of Birth',
          subtext: 'Enter the first day of the month if the exact day is unknown.',
          type: 'date',
          validationRules: 'required',
          placeholder: 'Select a Date'
        },
        {
          id: 'sex',
          label: 'Sex',
          type: 'select',
          possibleValues: sexOptions.filter(option => option.value === 'female' || option.value === 'male'),
          validationRules: 'required',
          popperClass: 'sex-dropdown'
        },
        {
          id: 'childbearingPotential',
          label: 'Is participant of childbearing potential?',
          listDisplayLabel: 'Childbearing Potential',
          type: 'select',
          possibleValues: [
            { label: 'Yes', displayValue: 'Yes', value: true },
            { label: 'No', displayValue: 'No', value: false }
          ],
          validationRules: 'required',
          popperClass: 'childbearing-potential-dropdown',
          visible: false
        },
        {
          id: 'birthCity',
          label: 'City/Municipality of Birth',
          type: 'text',
          optional: true
        },
        {
          id: 'birthCountry',
          label: 'Country of Birth',
          type: 'select',
          possibleValues: countryOptions,
          optional: true,
          popperClass: 'birthCountry-dropdown'
        }
      ],
      studyDetailFields: [
        {
          id: 'cohortId',
          label: 'Choose Cohort',
          type: 'select',
          validationRules: 'required',
          possibleValues: [],
          popperClass: 'cohort-dropdown'
        },
        {
          id: 'confirmCohortId',
          label: 'Confirm Cohort',
          type: 'select',
          validationRules: 'required|sameAsCohort:@cohortIdValidation',
          possibleValues: [],
          popperClass: 'confirm-cohort-dropdown'
        }
      ],
      gdprFields: [
        {
          id: 'gdprConsentDate',
          label: 'Date GDPR Consent Signed',
          type: 'date',
          placeholder: 'Select a Date',
          'disable-before': '4/3/2020', // study start date
          'disable-after': new Date(), // today
          optional: true
        }
      ],
      newParticipant: {
        siteId: this.$route.params.siteId,
        firstName: '',
        middleName: '',
        familyName: '',
        emailAddress: '',
        birthDate: '',
        sex: '',
        childbearingPotential: '',
        birthCity: '',
        birthCountry: '',
        cohortId: null,
        confirmCohortId: null,
        subgroupIds: [],
        substudies: [],
        primaryGroupId: null // TODO: add participant to a Primary Group Type
      }
    }
  },
  computed: {

    cohortId() {
      return this.newParticipant.cohortId
    },

    subgroupIds() {
      return this.newParticipant.subgroupIds
    },

    /**
     * returns the value of a participants sex based on the sex and childbearing potential
     * chosen in the form
     * @returns {string}
     */
    participantSexValue() {
      if (this.newParticipant.sex === 'male') {
        return 'male'
      } else if (this.newParticipant.sex === 'female') {
        if (this.newParticipant.childbearingPotential) {
          return 'female_of_childbearing_potential'
        }
        return 'female_not_of_childbearing_potential'
      }

      return ''
    },

    // Object used for displaying data in review screen
    newParticipantData() {
      return {
        ...this.newParticipant,
        // convert subgroup ids into a string of labels for display in DataList
        subgroupIds: this.newParticipant.subgroupIds
          .map(subgroupId =>
            getCohortSubgroups(this.newParticipant.cohortId, this.cohorts)
              .find(subgroup => subgroup.id === subgroupId)
              .name
          ).join(', '),
        sex: this.updateSex(),
        childbearingPotential: this.newParticipant.childbearingPotential === false ? 'No'
          : this.newParticipant.childbearingPotential === true
            ? 'Yes' : '',
        cohortId: getCohortNameById(this.newParticipant.cohortId, this.cohorts),
        substudies: this.newParticipant.substudies.map(ss => ss.name).join()
      }
    },
    /**
     * Returns study detail fields for review step
     * @returns {Array}
     */
    studyReviewData() {
      // prepare data for display on the review screen, giving correct labels to fields
      let dataFields = this.studyDetailFields.reduce((fields, field) => {
        if (field.id === 'cohortId') {
          fields.push({ ...field, label: 'Cohort' })
        } else if (field.id === 'subgroupIds') {
          fields.push({ ...field, label: 'Cohort Subgroups' })
        }
        return fields
      }, [])

      if (this.isSubstudiesSupported) {
        dataFields = dataFields.concat({
          id: 'substudies',
          label: 'Sub-studies'
        })
      }

      dataFields = dataFields.concat(this.gdprFields)

      return dataFields
    }

  },
  watch: {
    'newParticipant.cohortId'(val) {
      return this.watchCohortIdsHandler(val)
    },
    'newParticipant.confirmCohortId'(val) {
      return this.watchCohortIdsHandler(val)
    },
    'newParticipant.birthDate'(val) {
      return this.watchBirthdateHandler(val)
    },
    'newParticipant.sex'(val) {
      return this.watchSexHandler(val)
    }
  },
  apollo: {
    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        update: data => {
          const cohortsAlphabetized = sortBy(
            compose(
              toUpper,
              propOr('', 'name')
            ),
            data.getStudyCohorts
          )
          const possibleValues = cohortsAlphabetized.map(cohort => {
            return {
              label: cohort.name,
              value: cohort.id
            }
          })
          this.studyDetailFields.forEach(study => {
            study.possibleValues = possibleValues
          })
          return cohortsAlphabetized
        },
        variables: {
          studyId: this.$route.params.studyId
        },
        error (error) {
          logError(error, 'EnrollParticipant.vue')
        }
      }
    }
  },
  methods: {
    // handles substudy select changes and sets the value for the newParticipant
    toggleSubstudiesAndSetNewValues(substudies) {
      this.toggleSubstudies(substudies)
      this.newParticipant.substudies = substudies.add
    },

    closeBirthdateValidationModals() {
      this.newParticipant.birthDate = null
    },

    /**
     * Controls the visibility of the childbearingPotential field
     * @param {string} sex
     */
    watchSexHandler(sex) {
      const childbearingPotentialField =
        this.personalInformationFields.find(field => field.id === 'childbearingPotential')

      if (sex === 'female') {
        childbearingPotentialField.visible = true
      } else {
        childbearingPotentialField.visible = false
        this.newParticipant.childbearingPotential = ''
      }
    },
    watchBirthdateHandler(birthDate) {
      const participantAge = birthDate ? getAge(birthDate) : null

      if (!birthDate) {
        // birthdate cleared, hide any validation modals
        this.cutoffDateModalVisible = false
        this.flexibleRangeDateModalVisible = false
      } else if (participantAge < 28) {
        this.cutoffDateModalVisible = true
      } else if (
        participantAge >= 28 &&
        participantAge < 30 &&
        !this.overrideEligibility
      ) {
        this.flexibleRangeDateModalVisible = true
      }
    },
    watchCohortIdsHandler(cohortId) {
      /**
       * if the cohortIDs match and that cohort id has associated subgroups
       * add the subgroups field to the form data
       */
      if (cohortId &&
        cohortId === this.newParticipant.cohortId &&
        cohortId === this.newParticipant.confirmCohortId
      ) {
        const cohortSubgroups = sortBy(
          compose(toUpper, propOr('', 'name')),
          getCohortSubgroups(cohortId, this.cohorts)
        )
        const mustBeOnlySubgroup = cohortSubgroups.find(subgroup => subgroup.mustBeOnly)

        if (cohortSubgroups.length) {
          this.studyDetailFields.push({
            id: 'subgroupIds',
            label: 'Choose Subgroups',
            type: 'multiselect',
            subtext: 'Select all that apply.',
            validationRules: mustBeOnlySubgroup
              ? { required: true, onlySubgroup: mustBeOnlySubgroup.id }
              : 'required',
            possibleValues: cohortSubgroups.map(subgroup => ({
              displayValue: subgroup.name,
              value: subgroup.id
            })),
            popperClass: 'enrollment-subgroup-dropdown'
          })
        }
      } else if (this.studyDetailFields.some(field => field.id === 'subgroupIds')) {
        // remove the field, since the cohort ids no longer match
        this.studyDetailFields = this.studyDetailFields.filter(field => field.id !== 'subgroupIds')
        // reset its part of state
        this.newParticipant.subgroupIds = []
      }
    },
    updateSex() {
      const currentSex = propOr('', 'sex', this.newParticipant)
      const data = find(propEq('value', currentSex), sexOptions)
      return propOr('', 'label', data)
    },

    async enrollParticipant() {
      this.loading = true
      try {
        const result = await this.createParticipant()
        const createdParticipant = result.data.createParticipant.id
        await this.linkParticipantToCohort(createdParticipant)
        if (this.newParticipant.substudies.length) {
          await this.addParticipantToSubstudies(createdParticipant, this.substudies)
        }
        try {
          await this.createParticipantVisitWindows(createdParticipant)
        } catch (e) {
          console.warn(e)
        }
        this.navigateToCreatedParticipant(createdParticipant)
      } catch (error) {
        logError(error, 'EnrollParticipant.vue Create Participant:')
      } finally {
        this.loading = false
      }
    },

    createParticipant() {
      return this.$apollo.mutate({
        mutation: CREATE_PARTICIPANT_MUTATION,
        variables: {
          siteId: this.newParticipant.siteId,
          firstName: this.newParticipant.firstName,
          familyName: this.newParticipant.familyName,
          middleName: this.newParticipant.middleName,
          emailAddress: this.newParticipant.emailAddress,
          birthDate: this.newParticipant.birthDate ? formatDateForAPI(this.newParticipant.birthDate) : null,
          gdprConsentDate: this.newParticipant.gdprConsentDate
            ? formatDateForAPI(this.newParticipant.gdprConsentDate)
            : null,
          sex: this.participantSexValue,
          birthCity: this.newParticipant.birthCity,
          birthCountry: this.newParticipant.birthCountry
        }
      })
    },

    /*
     * After a participant is created, it must be linked to a cohort.
     */
    linkParticipantToCohort(participantId) {
      return this.$apollo.mutate({
        mutation: LINK_PARTICIPANT_TO_COHORT_MUTATION,
        variables: {
          participantId: participantId,
          cohortId: this.newParticipant.cohortId,
          subgroupIds: this.newParticipant.subgroupIds
        },
        // We don't have enough information about the participant (like their visit schedule) to fill in all the
        // information directly for this query, so re-fetch it as soon as the participant is fully linked up
        refetchQueries: [
          {
            query: GET_SITE_PARTICIPANTS_QUERY,
            variables: {
              studyId: this.$route.params.studyId,
              siteId: this.newParticipant.siteId
            }
          }
        ]
      })
    },

    /*
     * After a participant is created, its visit windows must be created.
     */
    createParticipantVisitWindows(participantId) {
      return this.$apollo.mutate({
        mutation: CREATE_PARTICIPANT_VISIT_WINDOWS_MUTATION,
        variables: {
          participantId: participantId,
          studyId: this.$route.params.studyId
        }
      })
    },

    backToSite() {
      this.$router.push({ name: 'EDCHome' })
    },

    navigateToCreatedParticipant(participantId) {
      this.$router.push({
        name: 'visitSchedule',
        params: {
          ...this.$router.params,
          participantId: participantId
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .validation-modal {
    .modal__content {
      p {
        margin-top: 1rem;
      }
    }
  }
  .enroll-participant {
    .errant-birth-date-field {
      border: 1px solid $cortex;
      padding: 1rem;

      .errant-birth-date-value {
        border-left: 4px solid $error;
        padding-left: .5rem;
        margin-left: .125rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        margin-top: .5rem;
      }
    }
    .warning-svg {
      fill: $error;
      margin-right: 1rem;
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
    .searchable-multi-select__chips {
      width: 150%
    }

    p {
      margin: 0 0 1.5rem;
    }

    &__page {
      margin: 0 1rem;
      border: none;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin: 1rem 0 .5rem;

      &:first-of-type {
        margin-top: 2rem;
      }
    }

    h4 {
      margin: 0;
      margin-bottom: .5rem;
      @include text-style('title', 'small', 'bold')
    }

    section {
      padding: 1.5rem 0;
      border-bottom: 1px solid $cortex;

      p:last-child {
        margin-bottom: 0
      }
    }

    .bf-form {
      display: grid;
      max-width: 100%;
      grid-template: auto / 20rem 20rem 20rem;
      grid-column-gap: 2rem;
      grid-row-gap: 1.5rem;

      .field {
        grid-column: 1;
      }

      @media screen and (min-width: $breakpoint-tablet) {
        .field {
          &.field--middleName {
            grid-column: 2;
          }
        }
      }

      @media screen and (min-width: $breakpoint-xl) {
        .field {
          &.field--familyName {
            grid-column: 3;
          }
        }
      }
    }

    .datalist {
      max-width: 40rem;
      margin-top: .5rem;

      .datalist__field {
        margin-bottom: 1rem;
        grid-template: auto / 2fr 3fr;
      }

      &:first-child {
        margin-top: 1rem;
      }

      dt, dd {
        padding-bottom: .25rem;
        min-height: 2rem;
      }

      dt {
        grid-row: 1;
        grid-column: 1;
        border-bottom: 1px solid $cortex;
      }

      dd {
        grid-row: 1;
        grid-column: 2;
      }
    }

    .substudy-selector {
      .scroll-wrap {
        margin-left: -2rem;
        margin-right: -2rem;
      }

      .substudy-option {
        padding: 0 2rem;
      }
    }
  }
</style>
