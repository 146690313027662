<template>
  <div class="wizard-progress">
    <progress
      :max="steps.length"
      :value="currentStep"
    />
    <div class="wizard-progress__steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="[
          'wizard-progress__step',
          index + 1 === currentStep ? 'active' : ''
        ]"
      >
        <SvgIcon :name="getStepIcon(index)" />
        <span>{{ step.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  methods: {
    getStepIcon(index) {
      const stepIndex = index + 1 // account for 0 based index
      if (stepIndex === this.currentStep) {
        return 'not-started'
      } else if (stepIndex < this.currentStep) {
        return 'checkmark'
      } else if (stepIndex > this.currentStep) {
        return 'not-started'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard-progress {
    display: grid;
    grid-template: auto auto / 1fr;

    progress {
      grid-row: 1;
      height: 5px;
      width: 100%;
      max-width: 45rem;
      appearance: none;
      background-color: $axon;
      border: none;
      box-shadow: none;

      &::-webkit-progress-bar {
        background-color: $axon;
        border-radius: 0;
        box-shadow: none;
      }

      &::-webkit-progress-value {
        background-color: $dopamine;
      }

      &::-moz-progress-bar {
        background-color: $dopamine;
      }
    }

    svg {
      fill: $dopamine;
      width: 1rem;
      height: 1rem;
    }

    &__steps {
      grid-row: 2;
      padding: 1rem;
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
    }

    &__step {
      margin-right: 5rem;
      display: flex;
      align-items: center;

      span {
        margin-left: .5rem;
        @include text-style('interface', 'small', 'regular');
      }

      svg {
        margin-top: 1px; // centers the icon in the line
      }

      &.active {
        svg {
          background-color: $dopamine;
          border-radius: 50%;
        }
      }
    }
  }
</style>
